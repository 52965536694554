import React, { useContext } from "react"
import { Link } from "gatsby"
import { db, storage } from "../../firebase/config"
import { getDoc, doc, deleteDoc } from "firebase/firestore"
import { StaticImage } from "gatsby-plugin-image"
import { ListingContext } from "./context"
import {
  ImageContainer,
  LeftContent,
  RightContent,
} from "./AddListingProcess.styles"
import { deleteObject, ref } from "firebase/storage"

const Welcome = ({ drafts, arabic }) => {
  const { setCreateNewListingState, setIsLoading } = useContext(ListingContext)
  // delete the current draft
  const deleteDraft = draft => {
    // defining fucntion to delete the draft document
    const deleteDraft = () => {
      deleteDoc(doc(db, "property", draft.id))
        .then(() => {
          setIsLoading(false)
          window.location = "/add-listing"
        })
        .catch(() => {
          setIsLoading(false)
          alert("There was a problem deleting the document")
        })
    }

    setIsLoading(true)
    deleteObject(ref(storage, draft.data.featured_image.reference))
      .then(() => {
        const galleryImages = draft.data.gallery
        let count = 0
        if (galleryImages.length !== 0) {
          galleryImages.forEach(image => {
            deleteObject(ref(storage, image.reference))
              .then(() => {
                count++

                // checking to see if the count is equla to the length
                if (count === galleryImages.length) {
                  deleteDraft()
                }
              })
              .catch(() => {
                setIsLoading(false)
                alert("There was a problem deleting one of the images")
              })
          })
        } else {
          deleteDraft()
        }
      })
      .catch(() => {
        deleteDraft()
      })
  }

  return (
    <>
      <LeftContent>
        <div className="container">
          <div className="col-lg-6">
            <h1>{arabic == false ? "Add Lisiting" : "إضافة قائمة"}</h1>
            {/* {drafts && (
              <>
                <p>
                  {arabic == false
                    ? "It appears that you have an incomplete listing that has been saved as a draft. Would you like to create a new one ?"
                    : "يبدو أن لديك قائمة غير مكتملة تم حفظها كمسودة. هل ترغب في إنشاء واحدة جديدة؟"}
                </p>
                {drafts.map(draft => {
                  if (draft.data.isDraft) {
                    return (
                      <div className="draft-container" key={draft.id}>
                        <div className="draft-content">
                          <img
                            src={draft.data.featured_image.url}
                            alt="draft"
                            className="draft-image"
                          />
                          <div className="content">
                            <strong>{draft.data.title}</strong>
                            <p>
                              {draft.data.address},{draft.data.city}
                            </p>
                          </div>
                        </div>
                        <div className="draft-actions">
                          <button
                            className="delete"
                            onClick={() => deleteDraft(draft)}
                          >
                            <span>{arabic == false ? "DELETE" : "حذف"}</span>
                            <svg
                              id="Group_361"
                              data-name="Group 361"
                              xmlns="http://www.w3.org/2000/svg"
                              width="13.762"
                              height="14.527"
                              viewBox="0 0 13.762 14.527"
                            >
                              <path
                                id="Path_175"
                                data-name="Path 175"
                                d="M4.529,16a1.531,1.531,0,0,0,1.529,1.529H13.7A1.531,1.531,0,0,0,15.233,16V6.823H4.529ZM6.058,8.352H13.7V16H6.058Zm6.117-3.823V3H7.587V4.529H3V6.058H16.762V4.529Z"
                                transform="translate(-3 -3)"
                                fill="#477b11"
                              />
                              <path
                                id="Path_176"
                                data-name="Path 176"
                                d="M9,12h1.529v4.587H9Zm3.058,0h1.529v4.587H12.058Z"
                                transform="translate(-4.413 -5.119)"
                                fill="#477b11"
                              />
                            </svg>
                          </button>
                          <Link
                            className="continue"
                            to="/edit-listing"
                            state={{ listing: draft }}
                          >
                            {arabic == false ? "CONTINUE" : "استمر"}
                          </Link>
                        </div>
                      </div>
                    )
                  } else {
                    return <></>
                  }
                })}
              </>
            )}
            {drafts && (
              <div className="draft-container">
                {arabic == false
                  ? "Don't worry if you can't finish it now, you can save it and it will appear here!"
                  : "لا تقلق إذا لم تتمكن من الانتهاء منه الآن ، يمكنك حفظه وسيظهر هنا!"}
              </div>
            )}
            {drafts.length > 5 && (
              // <button
              //   className="new disabled"
              //   onClick={() => {
              //     alert(
              //       "You have reached the maximum number of listings. Please delete one in prder to proceed"
              //     )
              //   }}
              // >
              //   {arabic == false ? "CREATE A NEW LISTING" : "إنشاء قائمة جديدة"}
              <></>
              // </button>
            )} */}
            {/* {drafts.length && ( */}
            <button
              className="new"
              onClick={() => setCreateNewListingState(true)}
            >
              {arabic == false ? "CREATE A NEW LISTING" : "إنشاء قائمة جديدة"}
            </button>
            {/* )} */}
          </div>
        </div>
      </LeftContent>
      <RightContent>
        <ImageContainer>
          <StaticImage
            src="../../assets/images/add-listing.png"
            alt="login image"
            placeholder="tracedSVG"
            layout="fullWidth"
            className="login-image"
          />
        </ImageContainer>
      </RightContent>
    </>
  )
}

export default Welcome

/*

*/
